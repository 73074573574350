﻿export default function addDialogHandler() {
  document.addEventListener(
    'click',
    event => {
      const target = event.target as HTMLElement;

      if (!target.matches('[data-dialog-open]')) return;
      const dialog = document.getElementById(
        target.dataset.dialogOpen as string
      ) as HTMLDialogElement;
      if (!dialog) return;

      dialog.showModal();
    },
    false
  );

  document.addEventListener(
    'click',
    event => {
      const target = event.target as HTMLElement;

      if (!target.matches('[data-dialog-close]')) return;
      const dialog = document.getElementById(
        target.dataset.dialogClose as string
      ) as HTMLDialogElement;
      if (!dialog) return;

      dialog.close(`${false}`);
    },
    false
  );
}
