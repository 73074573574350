export function addModalEventHandlers() {
  document.addEventListener('click', event => {
    const target = event.target as HTMLElement;
    if (!target.matches('[data-action="openModal"]')) return;
    const id = target.dataset.target as string;
    const modal = document.querySelector<HTMLElement>(`[data-role="modal"][data-id="${id}"]`);
    if (!modal) return;

    modal.classList.remove('hidden');
    modal.classList.add('flex');
  });

  document.addEventListener('click', event => {
    const target = event.target as HTMLElement;
    if (!target.matches('[data-action="closeModal"]')) return;

    const id = target.dataset.target as string;
    const modal = document.querySelector<HTMLElement>(`[data-role="modal"][data-id="${id}"]`);
    if (!modal) return;

    modal.classList.remove('flex');
    modal.classList.add('hidden');
  });
}
