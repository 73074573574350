const TabTextActiveClass = 'text-primary';
const TabBgActiveClass = '!bg-primary';
const TabTextBgActiveClass = '!text-white';
const TabDisplayActiveClass = '!block';

export function addTabsHandlers() {
  addTabHeaderHandlers();
  addTabDropdownHandlers();
}

function addTabHeaderHandlers() {
  document.addEventListener('click', event => {
    const target = event.target as HTMLElement;
    if (target.dataset.action !== 'tabselect') return;

    const tabGroupId = target.dataset.tabGroupId!;
    const tabId = target.dataset.tabId!;
    const container = document.querySelector<HTMLElement>(
      `[data-tab-group-id="${tabGroupId}"][data-role="tabcontainer"]`
    );
    if (!container) return;

    const dropdownBtn = document.querySelector<HTMLButtonElement>(
      `[data-tab-group-id="${tabGroupId}"][data-role="tabcontainer"] button[data-toggle="dropdown"]`
    )!;

    for (const el of document.querySelectorAll<HTMLElement>(
      `[data-tab-group-id="${tabGroupId}"][data-action="tabselect-dropdown"]`
    )) {
      if (el.dataset.tabId === tabId) {
        el.classList.add(TabTextActiveClass);
      } else {
        el.classList.remove(TabTextActiveClass);
      }
    }

    for (const el of container.querySelectorAll<HTMLElement>(
      `[data-tab-group-id="${tabGroupId}"][role="tab"]`
    )) {
      if (el.dataset.tabId === tabId) {
        dropdownBtn.children[0].innerHTML = el.innerText.trim();
        el.classList.add(TabBgActiveClass, TabTextBgActiveClass);
      } else {
        el.classList.remove(TabBgActiveClass, TabTextBgActiveClass);
      }

      el.ariaSelected = `${el.dataset.tabid === tabId}`;
      el.tabIndex = el.dataset.tabid === tabId ? 0 : -1;
    }

    for (const el of container.querySelectorAll<HTMLElement>(
      `[data-tab-group-id="${tabGroupId}"][role="tabpanel"]`
    )) {
      if (el.dataset.tabId === tabId) {
        el.classList.add(TabDisplayActiveClass);
      } else {
        el.classList.remove(TabDisplayActiveClass);
      }

      el.ariaSelected = `${el.dataset.tabid === tabId}`;
      el.ariaExpanded = `${el.dataset.tabid === tabId}`;
      el.tabIndex = el.dataset.tabid === tabId ? 0 : -1;
    }
  });
}

function addTabDropdownHandlers() {
  document.addEventListener('click', event => {
    const target = event.target as HTMLElement;
    if (target.dataset.action !== 'tabselect-dropdown') return;

    const tabGroupId = target.dataset.tabGroupId!;
    const tabId = target.dataset.tabId!;
    const tabElement = document.querySelector<HTMLElement>(
      `[data-tab-group-id="${tabGroupId}"][data-tab-id="${tabId}"][data-action="tabselect"]`
    );
    if (!tabElement) return;
    tabElement.click();

    const dropdown = target.parentElement?.parentElement;
    if (!dropdown) return;

    const dropdownId = dropdown.dataset.id;
    const dropdownBtn = document.querySelector<HTMLButtonElement>(
      `[data-target="${dropdownId}"][data-toggle="dropdown"]`
    );
    if (!dropdownBtn) return;
  });
}
