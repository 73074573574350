import i18next from 'i18next';
import httpBackend from 'i18next-fetch-backend';
import { initReactI18next } from 'react-i18next';

export const DEFAULT_LANGUAGE = 'sk';

export function loadTranslations() {
  return i18next
    .use(httpBackend)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      backend: {
        loadPath: '/{lng}/localization.json',
      },
      lng: document.querySelector('html')?.lang ?? DEFAULT_LANGUAGE,
      fallbackLng: DEFAULT_LANGUAGE,
      interpolation: {
        escapeValue: false,
        prefix: '{',
        suffix: '}',
      },
    });
}
