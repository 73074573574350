export function addRegistrationFormHandler() {
  document.addEventListener('change', event => {
    const target = event.target as HTMLInputElement;
    if (!target.matches('[data-action="toggleAsOrganization"]')) return;

    const isOrganization = target.checked;

    const organizationFields = document.getElementById('organizationFields');
    organizationFields?.classList.toggle('hidden');

    const consentsFieldset = document.getElementById('consentsSection');
    const consentsEveryone = document.getElementById('consentsEveryone');

    const consentsCitizen = document.getElementById('consentsCitizen');
    consentsCitizen?.classList.toggle('hidden');

    const consentsCompany = document.getElementById('consentsCompany');
    consentsCompany?.classList.toggle('hidden');

    if (consentsEveryone) {
      consentsFieldset?.classList.remove('hidden');
    }

    if (isOrganization && !consentsCompany && !consentsEveryone) {
      consentsFieldset?.classList.add('hidden');
    } else if (isOrganization) {
      consentsFieldset?.classList.remove('hidden');
    }

    if (!isOrganization && !consentsCitizen && !consentsEveryone) {
      consentsFieldset?.classList.add('hidden');
    } else if (!isOrganization) {
      consentsFieldset?.classList.remove('hidden');
    }
  });
}
